<template>
  <div class="pay-panel">
    <el-row>
      <el-col :span="24"
              class="title">{{desc}}</el-col>
    </el-row>

    <el-row>
      <el-col :span="24"
              class="product-details">
        <p>订单标题：购买《{{title}}》</p>
        <p>订单编号：{{outTradeno}}</p>
        <p class="coupon-price" v-if="couponPrice!='' &&couponPrice!='0.00'">
          <span class="order-price">订单价格：￥{{price}}</span>
          <span>优惠劵抵抗：￥{{couponPrice}}</span>
          <span v-if="memberLvDiscount>0">会员等级折扣：{{memberLvDiscount/10}}折</span>
          <span>实际支付：￥{{realPrice}}</span>
        </p>
        <p class="coupon-price" v-else>
          <span>订单价格：￥{{price}}</span>
          <span v-if="memberLvDiscount>0">会员等级折扣：{{memberLvDiscount/10}}折</span>
          <span>实际支付：￥{{realPrice}}</span>
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :span="12"
              class="pay-icon">
        <div :class="{active: activePayType ==='wechatPay' }"
             @click="payType('wechatPay')"><img :src="imgUrlWechat"></div>
        <div :class="{active: activePayType ==='aliPay' }"
             @click="payType('aliPay')"><img :src="imgUrlAlipay"></div>
<!--        <div :class="{active: activePayType ==='balance' }"
             @click="payType('balance')"><img :src="imgUrlBalance"></div>-->
      </el-col>
      <el-col :span="12">
        <el-image class="qrcode"
                  :src="qrcodeImg"
                  fit="fit"></el-image>
        <div class="saomiao">
          <svg class="icon"
               aria-hidden="true">
            <use xlink:href="#icon-saomiao"></use>
          </svg>
          请扫描二维码支付
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mixinImgUrl from '@/utils/mixin/imgUrl.js'
import QRCode from 'qrcode'

export default {
  name: 'BuyTab',
  mixins: [mixinImgUrl],
  props: {
    // 二维码url
    qrcodeUrl: {
      type: String,
      defalut: ''
    },
    // 订单编号
    outTradeno: {
      type: String,
      defalut: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    couponPrice: {
      type: String,
      required: true,
      default: '0.00'
    },
    realPrice: {
      type: String,
      required: true,
      default: '0.00'
    },
    memberLvDiscount: {
      type: Number,
      required: false,
      default: 0
    },
    price: {
      type: Number,
      required: true,
      default: 0
    },
    desc: {
      type: String,
      required: true,
      default: '一年免费更新升级，仅支持自营项目使用，禁止用于外包'
    }
  },
  data () {
    return {
      activePayType: '', // 三种支付方式, 分别为:wechatPay、aliPay、balance
      qrcodeImg: ''
    }
  },
  methods: {
    payType (active) {
      this.activePayType = active
      this.$emit('payTypeChange', this.activePayType)
    }
  },
  created () {
    this.activePayType = 'wechatPay'
    this.$emit('payTypeChange', this.activePayType)
  },
  watch: {
    qrcodeUrl (value) {
      if (!value) {
        this.qrcodeImg = ''
        return false
      }
      if (value === '') {
        this.qrcodeImg = ''
        return false
      }
      QRCode.toDataURL(value).then(url => {
        this.qrcodeImg = url
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pay-panel {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;

  .title {
    background-color: #f2dede;
    color: #b94a48;
    min-height: 50px;
    padding: 10px 15px;
    line-height: 1.5;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }

  .product-details {
    margin: 15px 0;
    & > p {
      min-height: 32px;
      line-height: 1.5;
      padding: 5px 0;
      word-break: break-all;
    }
    .coupon-price {
      & > span {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 5px;
      }
      & > span.order-price {
        color: #cccccc;
        text-decoration: line-through;
      }
    }
  }

  .pay-icon {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    & > div.active {
      border: 1px solid #0d95e8;
    }
    & > div {
      border: 1px solid #ddd;
      box-sizing: border-box;
      width: 100%;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;

      img {
        width: 100%;
        max-width: 136px;
        height: auto;
        display: block;
        margin: 0 auto;
      }

      &:hover {
        border-color: #0d95e8;
      }
    }
  }

  .qrcode {
    width: 100%;
    max-width: 266px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .saomiao {
    background-color: #00c800;
    color: #fff;
    min-height: 50px;
    padding: 10px 15px;
    line-height: 1.5;
    border-radius: 3px;
    width: 100%;
    max-width: 266px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .icon {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .pay-panel {
    padding: 10px;

    :deep(.el-row) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    :deep(.el-col) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .product-details {
      margin: 10px 0;
      font-size: 14px;
    }

    .pay-icon {
      margin-bottom: 15px;

      & > div {
        padding: 12px;
        margin-bottom: 10px;
      }
    }

    .qrcode {
      max-width: 220px;
      margin-top: 10px;
    }

    .saomiao {
      max-width: 220px;
      min-height: 44px;
      font-size: 14px;
    }
  }
}
</style>
